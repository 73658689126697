
body {
    background: linear-gradient(
    to left,
    rgba(7, 27, 82, 1) 0%,
    rgba(0, 128, 128, 1) 100%
  );
    /* background-color: #0D293E; */
    color: #C2C9CE;
    padding: 100px;
    font-family: Barlow,helvetica neue,Helvetica,Arial,sans-serif;
    text-align: center;
    
  }

  
  h1 {
    color: white;
  }

  button {
    background-color: #ffe800;
    border: none;
    color: black;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 20px;
  }

  input {
    background-color: white;
    border: none;
    color: black;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 20px;
  }

  img {
    position: absolute;
  top: 28px;
  left: 40px;
  width: 130px;

    
  }
  
 